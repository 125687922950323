<template>
  <div class="tree-div">
    <Tree
      :data="treeData"
      @on-select-change="handleTree"
      :load-data="loadData"
      @on-toggle-expand='toggleExpand'
    ></Tree>
  </div>
</template>

<script>
export default {
  props: {
    code:{
            type:String,
            default:()=>{
                return ''
            }
        },
        name:{
            type:String,
            default:()=>{
                return ''
            }
        },
        addHouse:{
          type:Object,
            default:()=>{
                return {}
            }
        }
    },
  data() {
    return {
      treeData: [],
      custGlobalId: "",
      oemCode: "", //要从缓存拿
      orgName: '',  //社区名称
    };
  },
  created() {
    this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId;
    this.oemCode = parent.vue.oemInfo.oemCode;
  },
  mounted() {
      this.getTree(this.code)
  },
  methods: {
    // 点击展开子节点事件
   toggleExpand(val){

   },

    handleTree(data) {
      this.$emit('handleTree',data,this.treeData)
    },

    getTree(val) {
      this.treeData = [];
      if (val && val.length > 0) {
        this.$get("/gx/pc/organization/selectOrganizationByOrgCodeV2", {
          orgCode: this.code,
        }).then((re) => {
            if (re&&re.code === "200"&&re.dataList!=null) {
              let temp = re.dataList.map((item) => {    
                if(item.child == true){
                   return {
                        title: item.name,
                        orgCode: item.code,
                        orgName: item.name,
                        parentId: item.parentId,
                        orgId: item.id,
                        id:item.id,
                        loading: false,
                        children: [],
                        regionType:item.regionType,
                        orgLevel:item.orgLevel
                    }; 
                }else{
                   return {
                        title: item.name,
                        orgCode: item.code,
                        orgName: item.name,
                        parentId: item.parentId,
                        orgId: item.id,
                        id:item.id,
                        regionType:item.regionType,
                        orgLevel:item.orgLevel
                      
                    }; 
                }                    
              });
              this.treeData = temp;
            }
          });
      }
    },
    loadData(item, callback) {
      let params = {
        oemCode: parent.vue.oemInfo.oemCode,
        orgId: Number(item.id) || item.parentId,
        parentId: item.regionId ? item.regionId : 0,
        regionTypeFilter: ''
      };
      if (item.regionType === '2' || item.regionType === '3') {
        this.$get('/gx/pc/house/selectHouseTreeV2', {
          parentId: item.id,
        }).then(res => {
          if (res.code == '200' && res.dataList !== null) {
            let arr2 = res.dataList.map((e) => {  //liang
              if (e.regionType === "0") {
                //4：门牌号(无小区无楼栋)
                return {
                  title: e.name,
                  regionId: e.id,
                  oemCode: parent.vue.oemInfo.oemCode,
                  code: e.code,
                  parentId: e.parentId,
                  regionType: e.regionType,
                  orgCode: item.orgCode,
                  ...e
                }
              }
            })
            item.children = arr2
            callback(item.children);
          }
        })
      } else {
        this.$get("/gx/pc/region/selectRegionTreeV2", params)
            .then((res) => {
              if (res.code === "200" && res.dataList != null) {
                let arr = res.dataList.map((i) => {
                  if (i.regionType === "0") {
                    //4：门牌号(无小区无楼栋)
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      ...i
                    }
                  } else if (i.regionType === "4") {
                    //4：门牌号(无小区无楼栋)
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      ...i
                    }
                  } else if (i.regionType === "3") {     //fuck
                    //1：小区 2：小区的楼栋 3：单独的楼栋"
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  } else if (i.regionType === "2") {
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  } else if (i.regionType === "1") {
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  }
                });
                arr = arr.map(item => {
                  if (item.child === true ) {
                    return {
                      loading: false,
                      children: [],
                      ...item,
                    }
                  }else {
                    return {
                      ...item
                    }
                  }
                });

                item.children = arr
                callback(item.children);
              }else{
                callback(item.loading = false)
                this.$Message.warning({
                  content:'获取数据失败',
                  background:true
                })
              }
            }).catch((err) => {
          console.log(err);
        });
      }
    },
    // 递归插入房号
    pushHouse(houseList,addHouse){
      console.log(houseList,addHouse)
      houseList.map(item => {
        if((item.id == addHouse.orgId &&  addHouse.regionType == 4) || (item.id == addHouse.regionId && addHouse.regionType != 4)){
          if('children' in item && 'expand' in item){
            let obj = {}
            if(item.id == addHouse.orgId &&  addHouse.regionType == 4){
              obj = {
                title: addHouse.regionName,
                code: addHouse.regionCode,
                orgName: addHouse.regionName,
                parentId: addHouse.orgId,
                id:addHouse.regionId,
                regionType:addHouse.regionType,
              }
            }else if(item.id == addHouse.regionId && addHouse.regionType != 4){
              obj = {
                title: addHouse.houseNum,
                code: addHouse.houseCode,
                orgName: addHouse.houseNum,
                parentId: addHouse.regionId,
                id:addHouse.houseId,
                regionType:addHouse.regionType || 0,
              }
            }
            item.children.unshift(obj)
          }else{
             this.$set(item,'children',[])
             this.$set(item,'loading',false)
          }
        }else{
          if('children' in item && 'expand' in item){
            this.pushHouse(item.children,addHouse)
          }
        }
      })
    }
  },
  watch:{
    addHouse:{
      handler(val,oldval){
        if(val){
          this.pushHouse(this.treeData,val)
        }
      }
      
    }
  }
};
</script>

<style lang="less" scoped>
//编写css
  .tree-div{
      height:500px;
      overflow-y: scroll;
  }
</style>
