<template>
  <div>
      <Form label-position="left" :label-width="120" >
          <FormItem>
              <span slot="label" class="validate">姓名:</span>
              <Input placeholder="请输入您的真实姓名" :maxlength="20" v-model="editRow.name" />
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">人员类型:</span>
              <Select style="width:200px;margin-right:10px" v-model="editRow.personalType" placeholder="请选择人员类型" @on-change="changePerson">
                <Option v-for="(item,index) in personalOptions" :key="index" :value="item.dictKey">{{item.dictValue}}</Option>
             </Select>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">证件类型:</span>
              <Select style="width:200px;margin-right:10px" v-model="editRow.idType" placeholder="请选择证件类型" :disabled="true">
                 <Option v-for="(item,index) in categoryOptions" :key="index" :value="item.dictKey">{{item.dictValue}}</Option>
             </Select>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">证件号码:</span>
             <Input placeholder="请输入您的证件号码" :maxlength="20" v-model="editRow.idNum" />
          </FormItem>
          <FormItem v-if="editRow.idType != 1">
               <span slot="label" class="validate">性别:</span>
               <RadioGroup v-model="editRow.sex">
                    <Radio label="1">男</Radio>
                    <Radio label="2">女</Radio>
                </RadioGroup>
          </FormItem>
          <FormItem v-if="editRow.idType != 1">
               <span slot="label" class="validate">出生日期:</span>
                <DatePicker v-model="editRow.birthday" type="date" placeholder="请选择出生年月日" style="width: 200px" @on-change="changeDate"></DatePicker>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">所属社区:</span>
              <Tag type="dot" style="margin-right:20px" color="primary" closable @on-close="openSelect" v-if="!showSelect && editRow.orgName">{{editRow.orgName}}</Tag>
               <Cascader
                v-if="showSelect"
                v-model="dataScopeList"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
                @on-change="changeCascader"
              ></Cascader>
              <Button type="primary" @click="openSelect" v-if="!showSelect">{{editRow.orgName && editRow.orgName != ''?'切换社区':'选择社区'}}</Button>
             
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">现住地址:</span>
             <Input placeholder="请输入您的现住地址" search enter-button="选择" @on-search="searchLocal" :maxlength="20" v-model="editRow.address" @on-change="changeAddress"/>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">与住址关系:</span>
             <Select style="width:200px;margin-right:10px" v-model="editRow.userType" placeholder="请选择与住址关系">
                 <Option v-for="(item,index) in houseOptions" :key="index" :value="item.dictKey">{{item.dictValue}}</Option>
             </Select>
          </FormItem>
      </Form>
      <LiefengModal
          title="选择现住地址"
          :value="addrStatus"
          @input="addrStatusFn"
          :fullscreen="false"
          width="500px"
          class="edit"
        
        >
          <template v-slot:title>
            <Button type="primary" icon="ios-add" @click="addHouse" :disabled="addDis">创建房号</Button>
            <p style="margin: 5px 0 0 9px">原住址：{{oldAddr}}</p>  
          </template>
          <template v-slot:contentarea>
              <Tree v-if="showTree" :code="treeCode" :name="treeName" :addHouse="addSuccessHouse" @handleTree="handleTree"></Tree>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addrTip=true;addrStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave">保存</Button>
        </template>
      </LiefengModal>
      <LiefengModal
          title="创建房号"
          :value="addHouseStatus"
          @input="changeAddShouseStatus"
          :fullscreen="false"
          width="500px"
        >
          <template v-slot:contentarea>
            <p style="margin-bottom:30px">所属上级：{{selectTree && selectTree.length !=0 ? selectTree[0].orgName :''}}</p>
            <span slot="label" class="validate" style="display:inline-block;width:66px;margin-bottom: 20px;">房号:</span>
            <Input  :maxlength="20" v-model="houseData.houseName" style="width:400px"/>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addHouseStatus = false">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveAddHouse" :loading="addHouseLoading">保存</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
  import LiefengModal from "@/components/LiefengModal";
  import Tree from './tree'
  export default {
    props:{
        editRow:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        oldAddr:''
        // dataScopeList:{
        //     type:Array,
        //     default:()=>{
        //         return []
        //     }
        // }
    },
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengModal,
    Tree
    },
    data() {
      //这里存放数据
      return {
          personalOptions:[],
          categoryOptions:[],
          houseOptions:[],
          showSelect:false,
          // 发布范围列表
          publishList: [],
          dataScopeList:[],
          dataNameList:[],
          addrStatus:false,
          addrTip:false,
          showTree:false,
          treeCode:'',
          treeName:'',
          selectTree:[],
          address:'',
          treeData:[],
          // 创建房号
          addHouseStatus:false,
          houseData:{
            houseName:''
          },
          // 新增成功后的房号
          addSuccessHouse:{},
          addDis:true,
          addHouseLoading:false,

      }
    },
    //方法集合
    methods: {
        // 只要地址一改变，我就清掉houseCode
        changeAddress(){
          this.editRow.houseCode = ''
        },
        // 点击了树节点的东西
        handleTree(val,treeData){
          this.selectTree = val
          this.treeData = []
          this.treeData = treeData;
          if(this.selectTree.length == 0  ||  (this.selectTree.length != 0 && this.selectTree[0].regionType != 1 && this.selectTree[0].regionType != 2 && this.selectTree[0].regionType != 3 && this.selectTree[0].orgLevel != 6))
          this.addDis = true
          else this.addDis = false
          this.address = ''
          if(this.selectTree.length != 0)
          this.getCurrentTitle(this.selectTree[0].id,treeData);
        },

        //递归面包屑
    getCurrentTitle(id, treeData) {
      for(let k in treeData) {
        if(treeData[k].id == id) {
          this.address = (treeData[k].name || treeData[k].orgName) + this.address;
          if(!treeData[k].parentId) return;
          this.getCurrentTitle(treeData[k].parentId,this.treeData)
        }else if(treeData[k].children && treeData[k].children.length > 0){
          this.getCurrentTitle(id,treeData[k].children)
        }
      }
    },
        // 取消按钮
        addrSave(){  
          if(this.selectTree && this.selectTree.length ==0){
             if(this.dataScopeList.length ==0){
                this.$Message.warning({
                content:'请选择现住地址',
                background:true
              })
              return
             }
          }else{
            if(this.selectTree[0].regionType != '0' && this.selectTree[0].regionType != '4'){
                this.$Message.warning({
                content:'现住地址请选择到房号',
                background:true
                })
                return
             }else{  
              this.editRow.address = this.address
              this.editRow.houseCode = this.selectTree[0].code
              this.addrStatus = false
              this.showTree = false
              this.addrTip =false
              
            }
          }
        },
        // 保存按钮
        addrStatusFn(status){
          this.addDis = true
          if(!status && this.addrTip){
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }else{
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }
        },
        // 点击了选择现住地址的选择按钮事件
        searchLocal(){
          if(this.editRow.orgCode == ''){
            if(this.dataScopeList.length ==0){
                this.$Message.warning({
                content:'请先选择所属社区',
                background:true
              })
              return
            }else if(this.dataScopeList.length != 3){
               this.$Message.warning({
                  content:'所属社区请选择到社区',
                  background:true
                })
                 return
            }else{
              let code = this.dataScopeList[this.dataScopeList.length -1].split('-')
              this.treeCode = code[code.length -1]
              this.treeName = this.dataNameList[this.dataScopeList.length -1].label
              this.addrStatus = true
              this.$nextTick(()=>{
                this.showTree = true
              })
            }     
          }else{
            this.addrStatus = true
            this.$nextTick(()=>{
                this.showTree = true
              })
            this.treeCode = this.editRow.orgCode
            this.treeName = this.editRow.orgName
          }
        },
        // 改变日期事件
        changeDate(val){
          this.editRow.birthday = val
          
        },
        // 选择级联事件
        changeCascader(val,value){     
          this.dataNameList = []
          this.dataNameList = value
          this.$emit('changeCascader',value)          
        },
        // 点击关闭标签事件
        // closeTag(){
        //   this.editRow.orgName = ''
        //   this.editRow.orgCode = ''
        //   this.showSelect =true
        // },
        // 点击切换社区按钮
        openSelect(){
            this.showSelect = true
            this.editRow.orgName = ''
            this.editRow.orgCode = ''
            this.editRow.address = ''
            this.editRow.houseCode = ''
            
            // 获取广州市的区
            this.getPulishData(4401, "publishList");
        },
        // 改变证件类型下拉框
        changePerson(val){
            if(val == 4){
                this.editRow.idType = '3'
            }else{
                this.editRow.idType = val
            }
            
            
        },
        // 字典接口
        getOption(data,model){
            this.$get('/old/api/common/sys/findDictByDictType',{
                dictType:data
            }).then(res=>{
                this[model] = res.dataList          
            })
        },
        // 获取区
        getPulishData(code, list) {
        this.$get("/old/api/pc/staff/selectCascadeDataScope", {
            orgCode: code,
            oemCode: parent.vue.oemInfo.oemCode,
            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
        }).then(res => {
            if (res.code == 200) {
            this[list] = res.dataList.map(item => {
                return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: []
                };
            });
            }
        });
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
          item.loading = true;
          let arrCode = item.value.split("-");
          this.$get("/old/api/pc/staff/selectCascadeDataScope", {
            orgCode: arrCode[arrCode.length - 1],
            oemCode: parent.vue.oemInfo.oemCode,
            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
          }).then(res => {
            if (res.code == 200) {
              if (!item.index) {
                item.children = res.dataList.map(item => {
                  return {
                    value: item.orgPath,
                    label: item.orgName,
                    orgLevel: item.orgLevel,
                    loading: false,
                    children: [],
                    index: 1
                  };
                });
              } else {
                item.children = res.dataList.map(item => {
                  return {
                    value: item.orgPath,
                    label: item.orgName,
                    orgLevel: item.orgLevel,
                    index: 1
                  };
                });
              }
              item.loading = false;
              callback();
            }
          });
        },
        formatterData(data,id){      
          let parentId = ''
          if(data && data.length != 0){
            data.map(item =>{ 
              if(parentId == ''){
                if(item.id == id){
                  this.address = item.name + this.address
                  parentId = item.parentId
                  this.formatterData(item.children,parentId)
                }else{
                  if(item.children && item.children.length != 0){
                    this.formatterData(item.children,id)
                  }
                }
              }else{
                if(item.parentId == id){
                  this.address = item.name + this.address
                }else{
                  if(item.children && item.children.length != 0){
                    this.formatterData(item.children,parentId)
                  }
                }
              }
              
              // if(item.children && item.children.length !=0){
              //   this.formatterData(item.children,id)
              // }else{
              //   console.log('id',id);
              //   console.log('parentId',item.parentId);
              // }   
            })
          }
          return this.address
        },
        // 创建房号
        addHouse(){
          console.log(this.selectTree)
          
          if(this.selectTree.length == 0  ||  (this.selectTree.length != 0 && this.selectTree[0].regionType != 1 && this.selectTree[0].regionType != 2 && this.selectTree[0].regionType != 3 && this.selectTree[0].orgLevel != 6)){
            this.$Message.warning({
              content:'请点击选择非房号架构进行新增',
              background:true
            })
          }else{
              this.addHouseStatus = true
          }

        },
        changeAddShouseStatus(val){
          this.addHouseStatus = val
        },
        saveAddHouse(){
          this.addHouseLoading = true
          this.$post('/gx/pc/region/regionAdd',{
            communityCode: this.treeCode,   //社区编码
            orgCode: this.selectTree[0].orgCode || '',    //街巷编码
            parentId: this.selectTree[0].regionId ||  0,  //
            regionName: this.houseData.houseName, //区域名称
            regionType: this.selectTree[0].orgLevel == 6 ? 4 : 0,  //区域类型
            seq: 1,   //排序
            regionDesc: 0,   //区域描述
          }).then(res => {
            this.addHouseLoading = true
            if(res.code == 200){
              this.$Message.success({
                content:'新增成功',
                background:true,
              })
              this.houseData = {houseName:''}
              this.addHouseStatus = false
              if(('expand' in this.selectTree[0] || !('children' in this.selectTree[0])) && res.data){
                this.addSuccessHouse = res.data
                console.log('展开才插入')
                console.log(this.addSuccessHouse)
              }
              
            }else{
              this.$Message.error({
                content:'新增失败',
                background:true,
              })
            }
          }).catch( err => {
            this.$Message.error({
              content:'新增失败',
              background:true,
            })
          })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getOption('CREDENTIALS_TYPE','categoryOptions')
        this.getOption('PERSONAL_TYPE','personalOptions')
        this.getOption('ONLINE_HOME_USER_TYPE','houseOptions')
        
    },
    watch:{
      editRow:{
        handler(val,newVal){
          this.showSelect =false
          this.treeCode = val.orgCode
          this.treeName = val.orgName
          this.address = '';
        }
      }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
</style>